/* eslint-disable no-promise-executor-return */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import { postMessageToParent } from "./parentWindow"
import { isWindowMessageValid } from "./validator"
import * as auth from "../../auth"
import { postShowToastNotification, ToastState } from "./toast"
import { captureException } from "../../../services/sentry"
import * as phoneNumber from "../../phoneNumber"
import {
  checkPhoneNumberValidity,
  formatPhoneNumberWithAccountCountry,
} from "../account/util"
import { LocalStorageKey } from "../../localStorageKey"
import { inIframe } from "../../environment"

const heartbeatKey = new LocalStorageKey("ikExtensionHeartbeat")

export const onInitializeOvermind = ({ actions, state }, instance) => {
  instance.reaction(
    ({ account }) => account.data,
    (d) => {
      postMessageToParent({
        name: "event_bus_initialized",
        origin: window.location.href || "none",
        parameters: { is_logged_in: auth.isLoggedIn() },
      })
    }
  )

  // Only register events for the chat_component siderail
  if (!window.location.href.includes("chat_component")) return

  window.addEventListener("message", (message) => {
    if (!isWindowMessageValid(message)) return
    const { data } = message

    const events = [
      "com.ikeono.send_sms_quote",
      "send_special_order_message",
      "com.ikeono.send_vend_receipt__request",
      "com.ikeono.send_vend_receipt__request",
      "",
    ]

    if (!auth.isLoggedIn()) {
      if (inIframe() && events.includes(data.name)) {
        postShowToastNotification(
          "Please login to www.ikeono.com to complete this action.",
          ToastState.WARNING
        )
      }
      return
    }

    if (
      state.account.incompleteProfile ||
      state.account.isTrialExpired ||
      !state.account.data
    )
      return

    const { parameters } = data
    if (data.name === "com.ikeono.send_sms_quote") {
      console.log("IKEONO Unhandled messagesend_sms_quote")
    } else if (data.name === "com.ikeono.send_workorder_quote") {
      actions.browserExtensionEventBus.sendWorkorderQuote({
        mobilePhone: parameters?.mobile_phone,
        id: parameters?.id,
      })
    } else if (data.name === "lightspeed_ui_data__request") {
      actions.browserExtensionEventBus.fetchLightspeedUiDataHandler({
        primaryId: parameters.primaryId,
        primaryType: parameters.primaryType,
      })
    } else if (data.name === "send_special_order_message") {
      const { phone, firstName, lastName, title } = parameters
      actions.browserExtensionEventBus.sendSpecialOrderMessage({
        phone,
        firstName,
        lastName,
        title,
      })
    } else if (data.name === "com.ikeono.send_vend_receipt__request") {
      const { saleId, phoneNumber } = parameters
      actions.browserExtensionEventBus.sendVendReceipt({ saleId, phoneNumber })
    } else if (data.name === "get_unread_convo_count") {
      actions.browserExtensionEventBus.getUnreadCount()
    } else if (data.name === "com.ikeono.load_messages") {
      // Reload the window if we're currently logged in
      // if (!state.account.data && auth.isLoggedIn()) {
      //   window.location.reload()
      // }
      actions.browserExtensionEventBus.openThread(data.parameters)
    } else {
      console.log(`Ikeono Unhandled message: ${data.name}`)
    }
  })
  postMessageToParent({
    name: "event_bus_initialized",
    parameters: { is_logged_in: auth.isLoggedIn() },
  })
}

export const postNewMessage = () => {}

export const getUnreadCount = async ({ effects }) => {
  // If the user logs into via the siderail in the extension the ext posts this message prematurely. For now it's
  // easier to drop the first request when the external id isn't set yet.

  if (auth.getShopExternalId() === "353ac1c3-6053-4371-a10f-c443152995db")
    return
  if (!auth.getShopExternalId()) {
    console.log(`Attempting to get unread count without an exid. Returning`)
    return
  }
  try {
    const data = await effects.browserExtensionEventBus.getUnreadCount()
    postMessageToParent({
      name: "on_get_unread_convo_count",
      parameters: {
        count: data.unread_count,
      },
    })
  } catch (error) {
    const { status } = error.response
    if (status === 401 || status === 403) {
      auth.logout()
    }
  }
}

export const sendSpecialOrderMessage = async (
  { state, effects },
  { phone, firstName, lastName, title }
) => {
  if (state.account.isReadonly) {
    postShowToastNotification(
      "Unable to send message. Your account is read only.",
      ToastState.WARNING
    )
    return
  }
  if (!state.account.data.config.extension.special_order_message_enabled) {
    postShowToastNotification(
      "You need to upgrade your account to send special order messages.",
      ToastState.WARNING
    )
    return
  }

  const error = phoneNumber.checkPhoneNumberValidity(phone)
  if (error) {
    postShowToastNotification(`Phone Issue: ${error}`, ToastState.ERROR)
    return
  }

  try {
    const data = await effects.browserExtensionEventBus.sendSpecialOrderMessage(
      { phone, firstName, lastName, title }
    )
    const { status, message } = data

    if (status === "ERR") {
      postShowToastNotification(
        message || "There was an error. Please try again.",
        ToastState.ERROR
      )
    } else {
      postShowToastNotification("Successfully sent special order message.")
    }
  } catch (error) {
    postShowToastNotification(
      "There was an error sending the special order message.",
      ToastState.ERROR
    )
  }
}

export const postCurrentLoggedInStatusChange = () => {}

export const sendWorkorderQuote = async (
  { state, effects },
  { mobilePhone, id }
) => {
  const { text_receipts_enabled } = state.account.data.config.extension
  if (!text_receipts_enabled) {
    postShowToastNotification(
      "Please upgrade your Ikeono.com account to text receipts and quotes.",
      ToastState.WARNING
    )
    return
  }

  try {
    postShowToastNotification("Workorder quote sent 🎉", ToastState.SUCCESS)
    await effects.browserExtensionEventBus.sendWorkorderQuote({
      mobilePhone,
      id,
    })
  } catch (error) {
    const errorMessage = error?.response?.data?.detail
    if (errorMessage) {
      postShowToastNotification(errorMessage, ToastState.ERROR)
    } else {
      postShowToastNotification(
        "Error sending quote. Please try again.",
        ToastState.ERROR
      )
    }
    console.error(error)
    captureException(error)
  }
}

export const fetchLightspeedUiDataHandler = async (
  { effects },
  { primaryId, primaryType }
) => {
  if (!primaryId) return

  const payload = {
    name: "lightspeed_ui_data__response",
    parameters: {
      primaryId,
      data: null,
      status: 0,
    },
  }
  try {
    const response =
      await effects.browserExtensionEventBus.fetchLightspeedUiDataHandler({
        primaryId,
        primaryType,
      })
    payload.parameters.data = response.data
    payload.parameters.status = 200
  } catch (error) {
    captureException(error)
    payload.parameters.status = 500
  }

  postMessageToParent(payload)
}

export const sendVendReceipt = async ({ effects }, { saleId, phoneNumber }) => {
  const payload = {
    name: "com.ikeono.send_vend_receipt__response",
    parameters: {
      saleId,
      status: 200,
    },
  }
  try {
    await effects.browserExtensionEventBus.sendVendReceipt({
      saleId,
      phoneNumber,
    })
  } catch (error) {
    captureException(error)
    payload.parameters.message = error?.response?.data?.message
    payload.parameters.status = error?.response?.status || 500
  }
  postMessageToParent(payload)
}

export const postUnreadCountChange = (overmind, count) => {
  postMessageToParent({
    name: "on_get_unread_convo_count",
    parameters: { count },
  })
}

export const postUserChangedLocation = ({ actions }) => {
  actions.browserExtensionEventBus.getUnreadCount()
}

export const openThread = async ({ state, actions }, params) => {
  const { phoneNumber, firstName, lastName, saleAmount } = params

  state.browserExtensionEventBus.params = params
  const phoneError = checkPhoneNumberValidity(phoneNumber)
  state.browserExtensionEventBus.phoneError = phoneError
  state.browserExtensionEventBus.phoneNumber = phoneNumber
  if (phoneError) {
    return
  }

  const receiverId = formatPhoneNumberWithAccountCountry(phoneNumber, "E164")
  if (state.activeThread.detail?.receiverId === receiverId) {
    return
  }

  state.activeThread.isLoading = true
  state.browserExtensionEventBus.error = null
  actions.activeInbox.clearCurrentThread()

  try {
    const vendorId = params.customerId
    const vendorSource = vendorId ? params.customerVendorSourceId : null

    const data = await actions.activeInbox.createThread({
      receiverId,
      name: `${firstName || ""} ${lastName || ""}`,
      hidden: true,
      vendorId,
      vendorSource,
      navigateToThread: false,
    })
    await actions.activeThread.get(data.id)
  } catch (error) {
    state.browserExtensionEventBus.error = error
  }

  state.activeThread.isLoading = false
}

const sleep = (ms) => new Promise((r) => setTimeout(r, ms))

export const checkExtensionIsInstalled = async ({ state }) => {
  state.browserExtensionEventBus.isLoading = true

  // Only check once per session. the heartbeat date can become stale since
  // the Browser Extension is only loaded once and the heartbeat date is set
  // when the extension first loads. If the user has a long session in the webapp
  // then it will falsely think the ext is uninstalled. So assume that if the ext
  // was detected as installed on first call then it's still installed.
  if (state.browserExtensionEventBus.isExtensionInstalled) return

  await sleep(250)

  const extensionHeartbeatDateInSeconds =
    heartbeatKey.getTimeDifferenceFromNow()
  state.browserExtensionEventBus.secondsSinceLastHeartbeat =
    extensionHeartbeatDateInSeconds
  state.browserExtensionEventBus.isExtensionInstalled =
    extensionHeartbeatDateInSeconds < 5 || inIframe()
  state.browserExtensionEventBus.isLoading = false
}
