import React from "react";
import { useFormikContext } from "formik";
import LabeledField from "../components/Form/LabeledField";
import { useState } from "../lib/overmind";

const EinField = () => {
  const { values, setFieldValue } = useFormikContext();
  const state = useState().account;

  const canEditEin = state.data.ui_metadata.can_edit_account_ein;

  return (
    <LabeledField
      required
      label={
        <span>
          <span className="badge bg-danger me-1">Required</span>EIN
          {" (Only numbers)"}
        </span>
      }
      name="ein"
      disabled={!canEditEin}
      maxLength={9}
      minLength={9}
      onChange={(e) => {
        e.preventDefault();
        const { value } = e.target;
        const regex = /\d+/g;
        const matches = value.match(regex);
        setFieldValue("ein", matches ? matches.join("") : "");
      }}
    />
  );
};

export default EinField;
