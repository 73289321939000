import React from "react";
import SimpleForm from "../components/Form/SimpleForm";
import { SettingsFactory } from "../components/Settings/SettingsFactory";
import { get, put } from "../lib/api";
import { prependHttps } from "../lib/url";
import { accountNameField, einField } from "./CommonAccountFields";

const fields = [
  accountNameField,
  einField,
  {
    label: "Email",
    body: "Important communication will be sent here including information about your subscription.",
    props: {
      type: "email",
      name: "email",
    },
  },
  {
    label: "Website",
    body: "",
    props: {
      name: "website",
      required: true,
    },
  },
  {
    label: "Address",
    body: "The physical address of your business.",
    as: "address",
  },
];

const AccountDetailForm = () => {
  const apiGetHandler = () => get("/shop/{exid}/account/primary");

  const apiSubmitHandler = async (data) => {
    data.website = prependHttps(data.website);
    await put("/shop/{exid}/account/primary", data);
  };
  return (
    <SimpleForm
      apiGetHandler={apiGetHandler}
      apiSubmitHandler={apiSubmitHandler}
    >
      <SettingsFactory fields={fields} />
    </SimpleForm>
  );
};

export default AccountDetailForm;
