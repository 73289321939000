/* eslint-disable camelcase */
import React from "react";
import SimpleForm from "../components/Form/SimpleForm";
import LabeledField from "../components/Form/LabeledField";
import { post } from "../lib/api";
import { login, setShopExternalId, setShopId } from "../lib/auth";
import { Cookie } from "../lib/cookie";

const initialPathCookie = new Cookie("initialPath");

const SignupForm = ({ onSuccess }) => {
  const apiSubmitHandler = async (data) => {
    data.initial_path = initialPathCookie.get();
    const response = await post("/shop/account", data);
    const { token, shop_id, shop_exid } = response.data;
    login({ token });
    setShopExternalId(shop_exid);
    setShopId(shop_id);
    onSuccess();
  };

  return (
    <SimpleForm
      resetForm={false}
      id="login-form"
      initialValues={{}}
      apiSubmitHandler={apiSubmitHandler}
    >
      <div className="row">
        <div className="col">
          <LabeledField
            label="First Name"
            name="first_name"
            className="form-control"
            required
            maxLength={50}
          />
        </div>
        <div className="col">
          <LabeledField
            label="Last Name"
            name="last_name"
            className="form-control"
            required
            maxLength={50}
          />
        </div>
      </div>
      <LabeledField
        label="Email"
        name="email"
        type="email"
        className="form-control"
        required
      />
      <LabeledField
        label="Password"
        name="password"
        type="password"
        className="form-control"
        required
        minLength={10}
      />
    </SimpleForm>
  );
};

export default SignupForm;
