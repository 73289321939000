import { Field } from "formik";
import React from "react";
import FormGroup from "./FormGroup";
import StandardFormGroup from "./StandardFormGroup";

export const LabeledFieldType = {
  none: "none",
  standard: "standard",
  formGroup: "formGroup",
};

const LabeledField = ({
  labelType = LabeledFieldType.standard,
  label,
  suffix,
  ...props
}) => {
  let computedClassName = props.className
    ? props.className
    : "form-control mb-3";
  if (props?.as === "select" && !props.className) {
    computedClassName = "form-select mb-2";
  }

  console.log("tmp: Rendering with props", props);

  if (labelType === LabeledFieldType.standard && label) {
    return (
      <StandardFormGroup
        label={label}
        fieldId={props.id}
        className={props.groupClassName}
      >
        <div className="input-group">
          <Field className={computedClassName} {...props} />
          <span hidden={!suffix} className="input-group-text" id="basic-addon2">
            {suffix}
          </span>
        </div>
      </StandardFormGroup>
    );
  }
  if (labelType === LabeledFieldType.formGroup && label) {
    return (
      <FormGroup label={label}>
        <Field className={computedClassName} {...props} />
      </FormGroup>
    );
  }
  return <Field className={computedClassName} {...props} />;
};

export default LabeledField;
